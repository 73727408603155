@import "https://fonts.googleapis.com/css2?family=Abhaya+Libre:wght@400;500&family=Tinos:ital,wght@0,400;0,700;1,400;1,700&display=swap";
* {
  box-sizing: inherit;
  margin: 0;
  padding: 0;
}

html {
  box-sizing: border-box;
  font-family: Tinos, serif;
  font-size: 62.5%;
  line-height: 1.3;
  overflow-x: hidden;
}

@media only screen and (max-width: 47.75em) {
  html {
    font-size: 55%;
  }
}

.container {
  max-width: 85.375em;
  margin: 0 auto;
  padding: 3rem;
}

.page-title {
  --heading-size: 4rem;
  font-size: var(--heading-size);
  text-align: center;
  text-transform: uppercase;
  color: #d40000;
  letter-spacing: calc(.1 * var(--heading-size));
  justify-content: center;
  align-items: center;
  margin-bottom: 1rem;
  font-family: Abyaha Libre, serif;
  font-weight: 400;
  display: flex;
  position: relative;
}

@media only screen and (max-width: 33.125em) {
  .page-title {
    --heading-size: 3rem;
  }
}

.page-title__wrapper {
  justify-content: center;
  align-items: center;
  padding-bottom: 2rem;
  display: flex;
}

.page-title:before {
  content: "";
  background-color: #1c1c1c;
  width: 120%;
  height: 1px;
  position: absolute;
  bottom: -1rem;
  left: 0;
  transform: translateX(-10%);
}

@media only screen and (max-width: 33.125em) {
  .page-title:before {
    width: 100%;
    transform: translateX(0);
  }
}

.page-title:after {
  content: "";
  background-color: #fff;
  border: 1px solid #1c1c1c;
  border-radius: 50%;
  width: 1rem;
  height: 1rem;
  position: absolute;
  bottom: -1.5rem;
  left: 50%;
  transform: translateX(-50%);
}

.page-title img {
  height: var(--heading-size);
  width: auto;
  margin: 0 1rem;
  transition: transform .2s ease-in-out;
}

.page-title img.left {
  transform: scaleX(-1);
}

.page-title img.right {
  transform: scaleX(1);
}

.page-title:hover img.left {
  transform: scaleX(-1)rotate(5deg);
}

.page-title:hover img.right {
  transform: scaleX(1)rotate(5deg);
}

.home .nav-wrapper {
  background-color: #ebded0;
  width: 100%;
  height: 100%;
  margin-top: -1rem;
  padding-top: 1rem;
}

.nav {
  max-width: 120rem;
  height: 6rem;
  margin: 0 auto;
  padding: 1rem 2rem 7rem;
  display: flex;
}

@media only screen and (max-width: 33.125em) {
  .nav {
    justify-content: space-between;
    align-items: center;
    padding-top: 4rem;
  }
}

.nav__background {
  z-index: 150;
  background-color: #d40000;
  border-radius: 50%;
  width: 4rem;
  height: 4rem;
  transition: all .8s cubic-bezier(.86, 0, .07, 1);
  display: none;
  position: fixed;
  top: 2rem;
  right: 2rem;
}

@media only screen and (max-width: 33.125em) {
  .nav__background {
    display: block;
  }

  .nav__background--open {
    transform: scale(80);
  }
}

.nav__burger {
  cursor: pointer;
  z-index: 300;
  background-color: #d40000;
  border: none;
  border-radius: 1rem;
  outline: none;
  width: 4rem;
  height: 4rem;
  display: none;
  position: relative;
}

@media only screen and (max-width: 33.125em) {
  .nav__burger {
    display: block;
    position: fixed;
    top: 2rem;
    right: 2rem;
  }
}

.nav__burger-line {
  background-color: #fff;
  border-radius: 2px;
  width: 2rem;
  height: 2px;
  transition: all .3s ease-in-out;
  position: absolute;
  left: 50%;
  transform: translate(-50%, -50%);
}

.nav__burger-line:first-child {
  top: calc(50% - .7rem);
}

.nav__burger-line:nth-child(2) {
  top: 50%;
}

.nav__burger-line:nth-child(3) {
  top: calc(50% + .7rem);
}

.nav__burger--open .nav__burger-line {
  height: 3px;
}

.nav__burger--open .nav__burger-line:first-child {
  top: 50%;
  transform: translate(-50%, -50%)rotate(45deg);
}

.nav__burger--open .nav__burger-line:nth-child(2) {
  top: 50%;
  transform: translate(-50%, -50%)rotate(-45deg);
}

.nav__burger--open .nav__burger-line:nth-child(3) {
  display: none;
}

.nav__logo {
  z-index: 200;
  width: 10rem;
  height: 6rem;
}

.nav__logo img {
  width: auto;
  height: 100%;
  display: block;
}

.nav__list {
  border-bottom: 2px solid #0000003b;
  flex-grow: 1;
  align-items: center;
  height: 4rem;
  margin: 0;
  padding: 0 3rem;
  list-style: none;
  display: flex;
}

@media only screen and (max-width: 33.125em) {
  .nav__list {
    opacity: 0;
    z-index: 200;
    border-bottom: none;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 0;
    height: 100vh;
    transition: all .8s cubic-bezier(.68, -.55, .265, 1.55);
    display: flex;
    position: fixed;
    top: 0;
    left: 0;
  }

  .nav__list--open {
    opacity: 1;
    width: 100%;
  }
}

.nav__link {
  color: #1c1c1c;
  font-size: 1.4rem;
  font-weight: 700;
  text-decoration: none;
}

.nav__link:hover {
  text-decoration: underline;
}

@media only screen and (max-width: 33.125em) {
  .nav__link {
    color: #fff;
    font-size: 3rem;
  }
}

.nav__item:not(:last-child) {
  margin-right: 1.5rem;
}

@media only screen and (max-width: 33.125em) {
  .nav__item:not(:last-child) {
    margin-bottom: 1.5rem;
    margin-right: 0;
  }
}

.paragraph {
  column-gap: 4rem;
  width: 100%;
  padding: 2rem 0;
  display: flex;
}

@media only screen and (max-width: 56.25em) {
  .paragraph {
    flex-direction: column;
    align-items: center;
    gap: 4rem 0;
  }
}

.paragraph > * {
  flex: 1;
}

.paragraph__photo {
  position: relative;
}

@media only screen and (max-width: 56.25em) {
  .paragraph__photo {
    max-width: 50rem;
  }
}

.paragraph__photo:before {
  content: "";
  background-color: #ebded0a3;
  width: 6rem;
  height: 2.25rem;
  position: absolute;
  top: 0;
  right: 10%;
  transform: rotate(45deg)translate(25%, -125%);
  -webkit-mask: conic-gradient(from 45deg at 0, #0000, #000 1deg 89deg, #0000 90deg) 0 / 51% 4px repeat-y, conic-gradient(from -135deg at 100%, #0000, #000 1deg 89deg, #0000 90deg) 100% / 51% 4px repeat-y;
  mask: conic-gradient(from 45deg at 0, #0000, #000 1deg 89deg, #0000 90deg) 0 / 51% 4px repeat-y, conic-gradient(from -135deg at 100%, #0000, #000 1deg 89deg, #0000 90deg) 100% / 51% 4px repeat-y;
}

@media only screen and (max-width: 47.75em) {
  .paragraph__photo:before {
    right: 0;
  }
}

.paragraph__photo:after {
  content: "";
  background-color: #ebded0a3;
  width: 6rem;
  height: 2.25rem;
  position: absolute;
  top: 0;
  transform: rotate(-45deg)translate(-25%, -125%);
  -webkit-mask: conic-gradient(from 45deg at 0, #0000, #000 1deg 89deg, #0000 90deg) 0 / 51% 4px repeat-y, conic-gradient(from -135deg at 100%, #0000, #000 1deg 89deg, #0000 90deg) 100% / 51% 4px repeat-y;
  mask: conic-gradient(from 45deg at 0, #0000, #000 1deg 89deg, #0000 90deg) 0 / 51% 4px repeat-y, conic-gradient(from -135deg at 100%, #0000, #000 1deg 89deg, #0000 90deg) 100% / 51% 4px repeat-y;
}

.paragraph__text {
  padding-top: .5rem;
  font-size: 1.6rem;
}

.paragraph img {
  width: 90%;
  display: block;
}

@media only screen and (max-width: 47.75em) {
  .paragraph img {
    width: 100%;
  }
}

.polaroid-paragraphs {
  gap: 4rem;
  font-size: 1.6rem;
  display: flex;
}

@media only screen and (max-width: 39.25em) {
  .polaroid-paragraphs {
    flex-direction: column;
    align-items: center;
    gap: 2rem 0;
  }
}

.polaroid-paragraphs > * {
  text-align: justify;
  width: 50%;
}

@media only screen and (max-width: 39.25em) {
  .polaroid-paragraphs > * {
    width: 100%;
  }
}

.polaroid-paragraphs__left .polaroid {
  float: left;
  margin: 0 2rem 1.5rem 0;
}

.polaroid-paragraphs__right .polaroid {
  float: right;
  margin: 0 0 1.5rem 2rem;
}

.polaroid {
  background-color: #ebded0;
  width: 18rem;
  height: 21rem;
  padding: 1rem 1rem 3rem;
  box-shadow: 0 .6rem .6rem #00000040;
}

.polaroid--large {
  transform-origin: center;
  background-color: #fff;
  width: 22rem;
  height: 25rem;
  padding: 1.5rem 1.5rem 3.5rem;
}

@media only screen and (max-width: 57.125em) {
  .polaroid--large {
    width: 18rem;
    height: 21rem;
    padding: 1rem 1rem 3rem;
  }
}

.polaroid img {
  object-fit: cover;
  width: 100%;
  height: 100%;
}

.polaroid-clothline {
  justify-content: space-between;
  gap: 2rem;
  margin: 4rem 0;
  display: flex;
  position: relative;
}

@media only screen and (max-width: 47.75em) {
  .polaroid-clothline {
    height: 40rem;
    display: block;
  }
}

@media only screen and (max-width: 26.875em) {
  .polaroid-clothline {
    height: 32rem;
  }
}

.polaroid-clothline:before {
  content: "";
  background-color: #1c1c1c;
  width: 200vw;
  height: 1px;
  position: absolute;
  top: 0;
  left: -100vw;
}

@media only screen and (max-width: 47.75em) {
  .polaroid-clothline:before {
    display: none;
  }
}

.polaroid-clothline__photo {
  background-color: #ebded0;
  max-width: 22rem;
  margin-top: 1rem;
  padding: 2rem 2rem 6rem;
  position: relative;
  box-shadow: 0 .6rem .6rem #00000040;
}

@media only screen and (max-width: 47.75em) {
  .polaroid-clothline__photo {
    min-width: 22rem;
    max-height: 30rem;
    transition: all .2s ease-in-out;
    position: absolute;
  }

  .polaroid-clothline__photo:first-child {
    top: 0;
    left: calc(50% - 10rem);
    transform: rotate(3deg)translateX(-50%);
  }
}

@media only screen and (max-width: 47.75em) and (hover: hover) and (pointer: fine) {
  .polaroid-clothline__photo:first-child:hover {
    z-index: 100;
    transform: rotate(3deg)translateX(-50%)scale(1.1);
  }
}

@media only screen and (max-width: 47.75em) {
  .polaroid-clothline__photo:first-child.touching {
    z-index: 100;
    transform: rotate(3deg)translateX(-50%)scale(1.1);
  }

  .polaroid-clothline__photo:nth-child(2) {
    top: 12rem;
    left: 50%;
    transform: rotate(0)translateX(-50%);
  }
}

@media only screen and (max-width: 47.75em) and (hover: hover) and (pointer: fine) {
  .polaroid-clothline__photo:nth-child(2):hover {
    z-index: 100;
    transform: rotate(0)translateX(-50%)scale(1.1);
  }
}

@media only screen and (max-width: 47.75em) {
  .polaroid-clothline__photo:nth-child(2).touching {
    z-index: 100;
    transform: rotate(0)translateX(-50%)scale(1.1);
  }

  .polaroid-clothline__photo:nth-child(3) {
    top: 0;
    left: calc(50% + 10rem);
    transform: rotate(-3deg)translateX(-50%);
  }
}

@media only screen and (max-width: 47.75em) and (hover: hover) and (pointer: fine) {
  .polaroid-clothline__photo:nth-child(3):hover {
    z-index: 100;
    transform: rotate(-3deg)translateX(-50%)scale(1.1);
  }
}

@media only screen and (max-width: 47.75em) {
  .polaroid-clothline__photo:nth-child(3).touching {
    z-index: 100;
    transform: rotate(-3deg)translateX(-50%)scale(1.1);
  }
}

@media only screen and (max-width: 26.875em) {
  .polaroid-clothline__photo {
    width: 18rem;
    min-width: 18rem;
    padding: 1rem 1rem 3rem;
  }

  .polaroid-clothline__photo:first-child {
    top: 0;
    left: calc(50% - 7rem);
  }

  .polaroid-clothline__photo:nth-child(3) {
    top: 0;
    left: calc(50% + 7rem);
  }
}

.polaroid-clothline__photo:before {
  content: "";
  background-color: #fff2e2a3;
  width: 6rem;
  height: 2.25rem;
  position: absolute;
  top: -1rem;
  left: 50%;
  transform: translateX(-50%);
  -webkit-mask: conic-gradient(from 45deg at 0, #0000, #000 1deg 89deg, #0000 90deg) 0 / 51% 4px repeat-y, conic-gradient(from -135deg at 100%, #0000, #000 1deg 89deg, #0000 90deg) 100% / 51% 4px repeat-y;
  mask: conic-gradient(from 45deg at 0, #0000, #000 1deg 89deg, #0000 90deg) 0 / 51% 4px repeat-y, conic-gradient(from -135deg at 100%, #0000, #000 1deg 89deg, #0000 90deg) 100% / 51% 4px repeat-y;
}

.polaroid-clothline__photo img {
  object-fit: cover;
  aspect-ratio: 1;
  width: 100%;
  height: auto;
  display: block;
}

@media only screen and (max-width: 47.75em) {
  .polaroid-clothline__photo img {
    width: 100%;
    height: 100%;
  }
}

.hero {
  background-color: #ebded0;
  flex-direction: column;
  align-items: center;
  padding-bottom: 0;
  display: flex;
}

.hero__title {
  text-transform: uppercase;
  letter-spacing: .5rem;
  color: #313638;
  text-align: center;
  font-family: Abyaha Libre, serif;
  font-size: 5rem;
  font-weight: 400;
}

.hero__polaroids {
  flex-direction: column;
  margin-top: 1rem;
  margin-bottom: 3rem;
  display: flex;
}

.hero__polaroids-row {
  justify-content: center;
  display: flex;
}

.hero__polaroids-row:first-child {
  z-index: 10;
}

.hero__polaroids-row:last-child {
  z-index: 5;
  margin-top: -2rem;
}

@media only screen and (max-width: 41.625em) {
  .hero__polaroids-row:first-child .polaroid:first-child, .hero__polaroids-row:first-child .polaroid:nth-child(4) {
    display: none;
  }
}

.livestream-container {
  justify-content: center;
  width: 100%;
  height: 50rem;
  margin-top: 4rem;
  display: flex;
  box-shadow: 0 .6rem .6rem #00000040;
}

.livestream-content {
  width: 100%;
  height: 100%;
  overflow: hidden;
}

/*# sourceMappingURL=index.44448711.css.map */
