.polaroid {
    width: 18rem;
    height: 21rem;
    padding: 1rem 1rem 3rem 1rem;
    background-color: #EBDED0;
    box-shadow: 0 .6rem .6rem rgba(0, 0, 0, .25);

    &--large {
        width: 22rem;
        height: 25rem;
        background-color: #fff;
        padding: 1.5rem 1.5rem 3.5rem 1.5rem;
        transform-origin: center;

        @media only screen and (max-width: 57.125em) {
            width: 18rem;
            height: 21rem;
            padding: 1rem 1rem 3rem 1rem;
        }
    }

    img {
        height: 100%;
        width: 100%;
        object-fit: cover;
    }
}

// This polaroid line turns into a collage on mobile.
.polaroid-clothline {
    display: flex;
    justify-content: space-between;
    gap: 2rem;
    margin: 4rem 0;
    position: relative;

    // Make the height fixed on mobile so the absolutely positioned photos
    // on mobile don't overlap the content below.
    @media only screen and (max-width: 47.75em) {
        height: 40rem;
        display: block;
    }

    @media only screen and (max-width: 26.875em) {
        height: 32rem;
    }

    // The "clothesline"
    &::before {
        content: "";
        position: absolute;
        top: 0;
        left: -100vw;
        height: 1px;
        width: 200vw;
        background-color: #1C1C1C;

        // Hide the line on mobile to make a photo collage.
        @media only screen and (max-width: 47.75em) {
            display: none;
        }
    }

    &__photo {
        // Default polaroid styling
        margin-top: 1rem;
        position: relative;
        padding: 2rem 2rem 6rem 2rem;
        background-color: #EBDED0;
        box-shadow: 0 .6rem .6rem rgba(0, 0, 0, .25);
        max-width: 22rem;

        @media only screen and (max-width: 47.75em) {
            // Make the photos absolutely positioned on mobile so they can overlap.
            position: absolute;
            max-height: 30rem;
            min-width: 22rem;
            transition: all .2s ease-in-out;

            // Position the photos in the collage on mobile.
            &:nth-child(1) {
                top: 0;
                
                // A bit to the left of center.
                left: calc(50% - 10rem);
                transform: rotate(3deg) translateX(-50%);

                @media(hover: hover) and (pointer: fine) {
                    &:hover {
                        transform: rotate(3deg) translateX(-50%) scale(1.1);
                        z-index: 100;
                    }
                }

                &.touching {
                    transform: rotate(3deg) translateX(-50%) scale(1.1);
                    z-index: 100;
                }
            }

            &:nth-child(2) {
                top: 12rem;
                
                // Centered.
                left: calc(50%);
                transform: rotate(0deg) translateX(-50%);

                @media(hover: hover) and (pointer: fine) {
                    &:hover {
                        transform: rotate(0deg) translateX(-50%) scale(1.1);
                        z-index: 100;
                    }
                }

                &.touching {
                    transform: rotate(0deg) translateX(-50%) scale(1.1);
                    z-index: 100;
                }
            }
            
            &:nth-child(3) {
                top: 0;
                
                // A bit to the right of center.
                left: calc(50% + 10rem);
                transform: rotate(-3deg) translateX(-50%);

                @media(hover: hover) and (pointer: fine) {
                    &:hover {
                        transform: rotate(-3deg) translateX(-50%) scale(1.1);
                        z-index: 100;
                    }
                }

                &.touching {
                    transform: rotate(-3deg) translateX(-50%) scale(1.1);
                    z-index: 100;
                }
            }
        }

        @media only screen and (max-width: 26.875em) {
            min-width: 18rem;
            width: 18rem;
            padding: 1rem 1rem 3rem 1rem;

            &:nth-child(1) {
                top: 0;
                left: calc(50% - 7rem);
            }

            &:nth-child(3) {
                top: 0;
                left: calc(50% + 7rem);
            }
        }


        // Add adhesive tape to the top of the polaroid for the effect of 
        // the photo being taped to the clothesline.
        $tape-size: 6rem;
        $mask: 
            conic-gradient(from 45deg at left,#0000,#000 1deg 89deg,#0000 90deg) left/51% 4.00px repeat-y,
            conic-gradient(from -135deg at right,#0000,#000 1deg 89deg,#0000 90deg) right/51% 4.00px repeat-y;

        &::before {
            content: '';
            position: absolute;
            top: -1rem;
            left: 50%;
            width: $tape-size;
            height: calc(0.375 * #{$tape-size});
            background-color: rgba(255, 242, 226, .64);
            -webkit-mask: $mask;
            mask: $mask;
            transform: translateX(-50%);
        }

        img {
            width: 100%;
            height: auto;
            object-fit: cover;
            aspect-ratio: 1/1;
            display: block;

            @media only screen and (max-width: 47.75em) {
                height: 100%;
                width: 100%;
            }
        }
    }
}
