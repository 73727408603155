@import "variables";

.page-title {
    --heading-size: 4rem;

    @media only screen and (max-width: 33.125em) {
        --heading-size: 3rem; 
    }

    font-size: var(--heading-size);
    font-weight: 400;
    margin-bottom: 1rem;
    text-align: center;
    text-transform: uppercase;
    font-family: $font-heading;
    color: #D40000;
    
    // Letter spacing is 10% of the font size.
    letter-spacing: calc(0.1 * var(--heading-size));

    // Allow the line to come under the text
    position: relative;

    display: flex;
    justify-content: center;
    align-items: center;

    // Wrapper to make sure the heading is centered and the line width 
    // is 100% of the heading width instead of the container width.
    &__wrapper {
        display: flex;
        justify-content: center;
        align-items: center;
        padding-bottom: 2rem;
    }

    // Line under the text
    &::before {
        content: "";
        
        position: absolute;
        left: 0;
        bottom: -1rem;
        height: 1px;
        width: 120%;
        background-color: #1C1C1C;

        // center the line
        transform: translateX(-10%);

        @media only screen and (max-width: 33.125em) {
            width: 100%;
            transform: translateX(0);
        }
    }

    // Circle in the middle of the line
    &::after {
        content: "";

        position: absolute;
        left: 50%;
        bottom: -1.5rem;
        height: 1rem;
        width: 1rem;
        background-color: #ffffff;
        border-radius: 50%;
        border: 1px solid #1C1C1C;
        transform: translateX(-50%);
    }


    // Elephants
    img {
        height: var(--heading-size);
        width: auto;
        margin: 0 1rem;
        transition: transform 0.2s ease-in-out;

        // Flip left elephant.
        &.left {
            transform: scaleX(-1);

        }
        
        &.right {
            transform: scaleX(1);
        }
    }

    // Rotate the elephants on hover
    &:hover {
        img.left {
            transform: scaleX(-1) rotate(5deg);
        }

        img.right {
            transform: scaleX(1) rotate(5deg);
        }
    }
}

