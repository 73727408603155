.home .nav-wrapper {
    background-color: #EBDED0;
    height: 100%;
    width: 100%;
    margin-top: -1rem;
    padding-top: 1rem;
}

.nav {
    display: flex;
    height: 6rem;
    max-width: 120rem;
    margin: 0rem auto;
    padding: 1rem 2rem 7rem 2rem;

    // Make sure the logo is left and burger menu is right on small screens
    @media only screen and (max-width: 33.125em) {
        justify-content: space-between;
        align-items: center;
        padding-top: 4rem;
    }

    // The background is a circle that will be scaled up to cover the whole screen
    &__background {
        display: none;
        position: fixed;
        top: 2rem;
        right: 2rem;
        height: 4rem;
        width: 4rem;
        z-index: 150;
        transition:all 0.8s cubic-bezier(0.86,0,0.07,1);
        background-color: #d40000;
        border-radius: 50%;

        @media only screen and (max-width: 33.125em) {
            display: block;

            &--open {
                transform: scale(80);
            }
        }
    }

    // The burger menu is a button with three lines
    &__burger {
        display: none;
        height: 4rem;
        width: 4rem;
        position: relative;
        background-color: #d40000;
        border-radius: 1rem;
        cursor: pointer;
         border: none;
        outline: none;
        z-index: 300;

        @media only screen and (max-width: 33.125em) {
            display: block;
            position: fixed;
            top: 2rem;
            right: 2rem;
        }

        &-line {
            height: 2px;
            width: 2rem;
            background-color: #fff;
            border-radius: 2px;
            transition: all 0.3s ease-in-out;
            
            position: absolute;
            left: 50%;
            transform: translate(-50%, -50%);

            &:nth-child(1) {
                top: calc(50% - 0.7rem);
            }

            &:nth-child(2) {
                top: 50%;
            }

            &:nth-child(3) {
                top: calc(50% + 0.7rem);
            }
        }

        // When the menu is open, the burger lines will be rotated to form an X,
        // one line will be hidden and the other two will be moved to the center
        &--open {
            .nav__burger-line {
                height: 3px;

                &:nth-child(1) {
                    top: 50%;
                    transform: translate(-50%, -50%) rotate(45deg);
                }

                &:nth-child(2) {
                    top: 50%;
                    transform: translate(-50%, -50%) rotate(-45deg);
                }

                &:nth-child(3) {
                    display: none;
                }
            }
        }
    }

    // The logo wrapper needs a fixed width, otherwise it will fill up half the space.
    &__logo {
        width: 10rem;
        height: 6rem;
        img {
            height: 100%;
            width: auto;
            display: block;
        }
        
        z-index: 200;
    }

    &__list {
        height: 4rem;
        display: flex;
        list-style: none;
        margin: 0;
        padding: 0;
        border-bottom: 2px solid rgba(0, 0, 0, 0.23);
        flex-grow: 1;
        padding: 0 3rem;
        align-items: center;

        @media only screen and (max-width: 33.125em) {
            opacity: 0;
            width: 0;
            height: 100vh;
            border-bottom: none;
            z-index: 200;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            position: fixed;
            top: 0;
            left: 0;
            transition: all .8s cubic-bezier(0.68, -0.55, 0.265, 1.55);

            // Slide the menu in from the left.
            &--open {
                opacity: 1;
                width: 100%;
            }
        }
    }

    &__link {
        color: #1C1C1C;
        text-decoration: none;
        font-size: 1.4rem;
        font-weight: 700;
        
        &:hover {
            text-decoration: underline;
        }

        @media only screen and (max-width: 33.125em) {
            color: #fff;
            font-size: 3rem;
        }
    }

    // Make sure the links are evenly spaced
    &__item {
        &:not(:last-child) {
            margin-right: 1.5rem;
        }

        @media only screen and (max-width: 33.125em) {
            &:not(:last-child) {
                margin-right: 0;
                margin-bottom: 1.5rem;
            }
        }
    }
}
