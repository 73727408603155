// -----------------------------------------------------------------
// Global styles
// -----------------------------------------------------------------
@import "variables";

@import url('https://fonts.googleapis.com/css2?family=Abhaya+Libre:wght@400;500&family=Tinos:ital,wght@0,400;0,700;1,400;1,700&display=swap');

* {
    margin: 0;
    padding: 0;
    box-sizing: inherit;
}

html {
    font-size: 62.5%; // 1rem = 10px; 10px/16px = 62.5%

    // Change size of rem on smaller screens
    @media only screen and (max-width: 47.75em) {
        font-size: 55%;
    }

    line-height: 1.3;
    box-sizing: border-box;
    font-family: $font-body;
    overflow-x: hidden;
}


// Container for the whole page
.container {
    max-width: 85.375em;
    margin: 0 auto;
    padding: 0 3rem;
    padding-bottom: 3rem;
    padding-top: 3rem;
}
