.livestream-container {
    display: flex;
    justify-content: center; // Center horizontally
    width: 100%;
    height: 50rem;
    margin-top: 4rem;
    box-shadow: 0 .6rem .6rem rgba(0, 0, 0, .25);
}

.livestream-content {
    width: 100%;
    height: 100%;
    overflow: hidden;
}