@import 'variables';

.hero {
    background-color: #EBDED0;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-bottom: 0;
    
    &__title {
        $font-size: 5rem;
        font-size: $font-size;
        font-family: $font-heading;
        text-transform: uppercase;
        font-weight: 400;
        letter-spacing: calc(0.1 * $font-size);
        color: #313638;

        text-align: center;
    }

    &__polaroids {
        display: flex;
        flex-direction: column;
        margin-top: 1rem;
        margin-bottom: 3rem;

        &-row {
            display: flex;
            justify-content: center;

            &:first-child {
                z-index: 10;
            }

            &:last-child {
                z-index: 5;
                margin-top: -2rem;
            }
        }

        @media only screen and (max-width: 41.625em) {
            &-row:first-child {
                .polaroid:nth-child(1), .polaroid:nth-child(4) {
                    display: none;
                }
            }
        }
    }
}
