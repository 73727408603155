.paragraph {
    display: flex;
    width: 100%;
    padding: 2rem 0;
    column-gap: 4rem;

    // Put the photo with adhesive tape under the text for mobile devices
    @media only screen and (max-width: 56.25em) {
        flex-direction: column;
        align-items: center;
        row-gap: 4rem;
        column-gap: 0;
    }

    & > * {
        flex: 1;
    }

    &__photo {
        position: relative;

        $tape-size: 6rem;
        $mask: 
            conic-gradient(from 45deg at left,#0000,#000 1deg 89deg,#0000 90deg) left/51% 4.00px repeat-y,
            conic-gradient(from -135deg at right,#0000,#000 1deg 89deg,#0000 90deg) right/51% 4.00px repeat-y;

        @media only screen and (max-width: 56.25em) {
            max-width: 50rem;
        }

        // put adhesive tape on the top right corner
        &::before {
            content: '';
            position: absolute;
            top: 0;
            right: 10%;
            width: $tape-size;
            height: calc(0.375 * #{$tape-size});
            background-color: rgba(235, 222, 208, .64);
            transform: rotate(45deg) translate(25%, -125%);
            -webkit-mask: $mask;
            mask: $mask;

            @media only screen and (max-width: 47.75em) {
                right: 0;
            }
        }

        // put adhesive tape on the bottom left corner
        &::after {
            content: '';
            position: absolute;
            top: 0;
            width: $tape-size;
            height: calc(0.375 * #{$tape-size});
            background-color: rgba(235, 222, 208, .64);
            transform: rotate(-45deg) translate(-25%, -125%);
            -webkit-mask: $mask;
            mask: $mask;
        }
    }

    &__text {
        padding-top: .5rem;
        font-size: 1.6rem;
    }

    img {
        width: 90%;
        display: block;

        @media only screen and (max-width: 47.75em) {
            width: 100%;
        }
    }
}

.polaroid-paragraphs {
    display: flex;
    font-size: 1.6rem;
    gap: 4rem;

    @media only screen and (max-width: 39.25em) {
        flex-direction: column;
        align-items: center;
        row-gap: 2rem;
        column-gap: 0;
    }
    
    & > * {
        text-align: justify;
        width: 50%;

        @media only screen and (max-width: 39.25em) {
            width: 100%;
        }
    }

    // Float the polaroid to the left for the first paragraph
    &__left {
        .polaroid {
            float: left;
            margin: 0 2rem 1.5rem 0;
        }
    }

    // Float the polaroid to the right for the second paragraph
    &__right {
        .polaroid {
            float: right;
            margin: 0 0 1.5rem 2rem;
        }
    }

}
